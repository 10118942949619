import request from '@/utils/http/request'

/** 下载 */
export async function DownloadOtherFile(
  params: {
    // query
    serviceCode?: string
    typeCode?: string
  },
  options?: { [key: string]: any },
) {
  return request<any>({
    url: 'upload/ImportTemplate/Download',
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  })
}
/** 上传图片 */
export async function WTMUploadFile(
  body: {
    isAddWatermark?: boolean
    imageType: string
    serviceCode: string
  },
  files?: File[],
  options?: { [key: string]: any },
) {
  const formData = new FormData()
  if (files) {
    formData.append('file', files[0] || '')
  }
  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele]

    if (item !== undefined && item !== null) {
      formData.append(
        ele,
        typeof item === 'object' ? JSON.stringify(item) : item,
      )
    }
  })
  return request<
    Array<{
      id: number
      key: string
      url: string
      size: string
    }>
  >({
    url: '/uploadApi/Upload/ImageToOSS',
    method: 'POST',
    noApiPrefix: true,
    data: formData,
    ...(options || {}),
  })
}

export async function getUserAccesToken(token: string, code: string) {
  return request<any>({
    url: 'open-apis/authen/v1/access_token',
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
      'fsToken': true,
      'fs': true,
    },
    data: {
      grant_type: 'authorization_code',
      code,
    },
  })
}

export async function OAuthLoginPOST(
  body: {
    /** 账号/手机号 */
    Account: string
    /** 密码 */
    Password: string
  },
  options?: { [key: string]: any },
) {
  return request<any>({
    url: `/userApi/OAuth/Login`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    data: body,
    ...(options || {}),
  })
}

/** 刷新token */
export async function OAuthRefreshTokenPOST(options: { [key: string]: any }) {
  return request<UserApi.LoginOutput>({
    url: `/userApi/OAuth/RefreshToken`,
    method: 'POST',
    // data: body,
    ...(options || {}),
  })
}
